@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';


@font-face {
    font-family: 'lufgabold';
    src: url('../src/assets/font/lufgabold-webfont.woff2') format('woff2'),
         url('../src/assets/font/lufgabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'lufgaregular';
    src: url('../src/assets/font/lufgaregular-webfont.woff2') format('woff2'),
         url('../src/assets/font/lufgaregular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'lufgamedium';
    src: url('../src/assets/font/lufgamedium-webfont.woff2') format('woff2'),
         url('../src/assets/font/lufgamedium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'Lufga SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('../src/assets/font/Lufga SemiBold'), 
    url('../src/assets/font/LufgaSemiBold.woff') format('woff');
    }
    
.font700{
    font-family: 'lufgabold' !important;
}
// .font500{font-family: 'lufgamedium';}
.font600{ font-family: 'Lufga SemiBold';}

body{
    font-family: 'lufgaregular';
    // background-color: #f8fafc;
}
.font500{
    font-weight: 500;
}
.font700{font-weight:700;}

.sign-in-block p::before {
    content: "";
    position: absolute;
    width: 122px;
    height: 1px;
    background-color: #ccc;
    top: 11px;
    left: 0;
}
.sign-in-block p::after {
    content: "";
    position: absolute;
    width: 122px;
    height: 1px;
    background-color: #ccc;
    top: 11px;
    right: 0;
}
.border {
    border-width: 1px;
    // border-color: #a5afc9;
}
// #customStyle:checked + div{background-color: #fff;}

.peer:checked ~ .peer-checked\:opacity-100 {
    opacity: 1;
}
.peer:checked ~ .peer-checked + div{
    background-color: red;
}
.forgot-password-block{background-image: url(../src/assets/images/forgot-background-img.png);}
// .forgot-password-block::before{background-image: url(../src/assets/images/cloude.png); content:'';
//      position: absolute; top:0px; height:234px; left: 0px; width: 100%;}
.active{color:#EDB73D;}
.owl-carousel .owl-item img {
    display: block;
    width: auto;
}
.owl-carousel .owl-item:nth-of-type(2)::before {
    background-image: url('../src/assets/images/mobile-screen.png');
    background-size:cover;
    background-repeat: no-repeat;
    width: 340px;
    height: 696px;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
  }
  .owl-carousel .owl-item:nth-of-type(2) {
    position: relative;
  }
  
// #owl-demo .owl-item:first-child div {
//     border: 6px solid #e1e1e1;
//   }


//Hiding the Month label from Calender
.mat-calendar-body-label[colspan="7"]{
  display: none !important;
}

///Hiding the Month label from Calender
.mat-calendar-body-label[colspan="1"],.mat-calendar-body-label[colspan="2"],.mat-calendar-body-label[colspan="3"],.mat-calendar-body-label[colspan="4"],.mat-calendar-body-label[colspan="5"],.mat-calendar-body-label[colspan="6"]{
  visibility: hidden !important;
}

.h-cal-right {
    height: calc(96vh - 138px);
    overflow-y: auto;
}
.h-cal-left {
    height: calc(96vh - 130px);
    overflow-y: auto;
}
.video-h-right {
    height: calc(91vh - 138px);
    overflow-y: auto;
}
body{
    background-color: #fafcff;
}
.owl-theme .owl-nav {
    margin-top: 44px;
}

.mat-calendar-body-active .mat-focus-indicator {
    background-color: #3E5BAB !important; /* Change this to your desired color */
    color: #ffffff !important; /* Change this to your desired text color */
}
.mat-calendar-body-cell{
    font-size: 16px !important;
    font-family: 'Lufga' !important;
    font-weight: 500;
}

button[disabled] {
    opacity: 0.5; /* Adjust opacity to visually fade the button */
    pointer-events: none; /* Prevent clicks on the button */
  }


.sidebar-tab .active{
    border: 1px solid #324989;
}

@media (max-width:767px){
// body{font-size: calc(.7em + 1vw);}
:root{font-size: 0.8rem}
.how_to_work img {
    width: 48px;
}
}
.ngx-otp-input{
    width: 56px !important;
    height: 56px !important;
    border-radius: 10px !important;
    background-color: #E9E9E9 !important;
    border: 0px !important;
}
.ngx-otp-input:focus {
    box-shadow: inherit;
}
        
// @media (min-width:1024px) and (max-width:481px){
//     .logo-block-img img {
//         width: 101px;
//     }
// }   

@media only screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 481px) and (max-height: 481px) 
  {
    .logo-block-img img {
                width: 101px; }

  }

  @media only screen and (min-width:1440px) and (max-width: 1440px) and (min-height: 534px) and (max-height: 534px) 
  {
    .logo-block-img img {width: 101px; }

  }
  .mat-datepicker-toggle.mat-datepicker-toggle {
    position: absolute;
    top: 6px;
    right: 0;
}
.swiper-overlay img {
    width: 213px;
}

select option { color: black; }
// select option:first-child{
//   color: green;
// }
// select::placeholder{color: #ccc;}

// select option:first-child{
//   color: green;
// }



// option {
//     color: black;
//   }

//   select:required:invalid {
//     color: gray;
//   }
//   option[value=""][disabled] {
//     display: none;
//   }
//   option {
//     color: black;
//   }


//   select {
//     color: #000000 !important;
//   }
//   option:not(:first-of-type) {
//     color: rgb(255, 0, 0) !important;
//   }

select:required:invalid { 
    color: gray !important;
  }
  // option[value=""][disabled] {
  //   display: none !important;
  // }
  option {
    color: rgb(224, 21, 21);
  }
  span.mdc-button__label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #1c1b1b;
} 
.cdk-overlay-connected-position-bounding-box {
    top: 78px !important;
}
.mdc-button {
    padding: 13px 8px 0 8px !important;
}
select#countries {
    padding-right: 37px;
}
select#countries option{
   text-align: left;
   padding-left: 0px;
   text-indent: 10px;
}


select{
    text-overflow: ellipsis;
    width: 179px;
    white-space: nowrap;
}

span.visually-hidden {
    display: none;
}
.star-rating span {
    font-size: 44px !important;
    color: #FFC107;
}

[type=checkbox], [type=radio] {
    color: #3E5BAB;
}
.has-\[\:checked\]\:bg-indigo-50:has(:checked) {
    background-color: transparent !important;
}
.star {
    position: relative;
    display: inline-block;
    color: #d3d3d3;
  }
  .full {
    color: #FFC107;
  }
  .half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color:  #FFC107;
  }
  .mdc-text-field{padding-left: 0px !important; width:100% !important;}
//   .multiple-block{width: 100% !important;}

//   .mat-select-panel {
//     background: red;
// }

.mat-mdc-form-field-infix {
    flex: auto;
    min-width: 280px !important;
    width: 590px !important;
}
.mat-mdc-form-field {
    display: block !important;
    flex-direction: column;
    min-width: 0;
    text-align: center !important;
}

.custom-width .mat-select {
    width: 200px;
  }
  
.custom-width .mat-form-field-flex {
    width: 200px;
}
  
.custom-width .mat-select-trigger {
    width: 200px;
  }
  .mat-block {
    background-color: #ECECEC;
    width: 40px;
    height: 40px;
    border-radius: 100px !important;
    padding: 8px;
    display: inline-block;
    margin: 0 5px;
}
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}


  .zoom-in-zoom-out {
    animation: zoom-in-zoom-out 2s ease-out infinite;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.2, 1.2);
    }
    100% {
      transform: scale(1, 1);
    }
  }
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    position: absolute;
    bottom: 9px;
    left: 0;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  left: 0 !important;
  right: auto !important;
}
// [type=checkbox]:checked {
//   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%233E5BAB%0A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='lucide lucide-check'%3E%3Cpath d='M20 6 9 17l-5-5'/%3E%3C/svg%3E") !important;
// }



select:required:invalid {
  color: gray;
}
select:invalid > option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}
.highlight-appointment-date {
  background-color: lightgreen; /* Or any other color you want */
  border-radius: 50%; /* Optional: make it circular */
  color: white; /* Optional: change text color */
}
.mat-mdc-icon-button svg {
  vertical-align: baseline;
  fill: #545151 !important;
}
.timepicker__header{
  padding: 15px 30px;
  background-color: #3E5BAB !important;
}
.timepicker-button{
  color: #3E5BAB !important;
}
.clock-face__number > span.active{
  background-color: #3E5BAB !important;
}

.clock-face__clock-hand {
  background-color: #3e5bab !important;
}
// [_nghost-vsl-c85] {
//   --clock-hand-color: #3e5bab !important;
// }

.clock-face__clock-hand_minute:before { border-color: #3e5bab!important;}
// .clock-face__number  {
//   --clock-hand-color: #3e5bab !important;
// }

.blue-light {
  background-color: rgb(241 245 249);
}
.red-light {
background-color: #fef1f1;
}
.appointment-date {
  background-color: #ffcc80 !important;
  border-radius: 50% !important;
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  opacity: 0 !important;
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  opacity: 0 !important;
}
.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #3e5bab !important;
}
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: #7c98e5 !important;
}


.appointment-date::before {
  width: 10px !important;
  height: 10px !important;
  background-color:#74C720;
  border-radius: 100px;
  bottom: -35px;
  left: 42% !important;
  right: auto;
  margin: auto;
}
.appointment-date{background-color: transparent !important;}
.LgbsSe-Bz112c {
  display: block;
  width: 20px;
}
.nsm7Bb-HzV7m-LgbsSe.Bz112c-LgbsSe .nsm7Bb-HzV7m-LgbsSe-Bz112c {
  margin: 0;
  padding: 1px 10px !important;
}